import {checkValid} from "@/components/globalModels/functions/ModelsValidate";

export function UserIE559Data() {

    this.checkValid = checkValid

    this.data = {
        userId: '',
        companyName: '',
        companyAddress: '',
        docNumber: '',
        vatNumber: '',
    }

    this.validation = {
        companyName: false,
        companyAddress: false,
        docNumber: false,
        vatNumber: false,
    }

    this.validationTranslate = {
        companyName: '',
        companyAddress: '',
        docNumber: '',
        vatNumber: '',
    }

    this.validationTxt = {
        companyName: false,
        companyAddress: false,
        docNumber: false,
        vatNumber: false,
    }

    /**
     * Getters
     */
    this.getCompanyName = () => {
        return this.data.companyName
    }
    this.getCompanyAddress = () => {
        return this.data.companyAddress
    }
    this.getDocNumber = () => {
        return this.data.docNumber
    }
    this.getVatNumber = () => {
        return this.data.vatNumber
    }

    /**
     * Setters
     */
    this.setCompanyName = (val) => {
        this.data.companyName = val
    }
    this.setCompanyAddress = (val) => {
        this.data.companyAddress = val
    }
    this.setDocNumber = (val) => {
        this.data.docNumber = val
    }
    this.setVatNumber = (val) => {
        this.data.vatNumber = val
    }
}

/**
 * Functions
 */

UserIE559Data.prototype.setItem = function(item) {
    this.setCompanyName(item?.company_name)
    this.setCompanyAddress(item?.company_address)
    this.setDocNumber(item?.doc_number)
    this.setVatNumber(item?.vat_number)
}

UserIE559Data.prototype.dataValidation = function() {
    let validationItems = {
        companyName: this.getCompanyName(),
        companyAddress: this.getCompanyAddress(),
        docNumber: this.getDocNumber(),
        vatNumber: this.getVatNumber(),
    }

    let validationOptions = {
        companyName: {type: ['empty']},
        companyAddress: {type: ['empty']},
        docNumber: {type: ['empty']},
        vatNumber: {type: ['empty']},
    }

    return this.checkValid(validationItems, validationOptions)
}

/**
 * Prepare data
 */

UserIE559Data.prototype.prepareData = function() {
    return {
        "company_name": this.getCompanyName(),
        "company_address": this.getCompanyAddress(),
        "doc_number": this.getDocNumber(),
        "vat_number": this.getVatNumber(),
    }
}

