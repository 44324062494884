<template>
  <IE559ReportTableSection
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
  />
</template>

<script>
  import IE559ReportTableSection from "./IE559ReportTableSection/IE559ReportTableSection";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {IE559ReportSocketMixin} from "@/mixins/socketMixins/socketMixin";

  export default {
    name: "IE559ReportTable",

    components: {
      IE559ReportTableSection,
    },

    mixins: [routeFilter, IE559ReportSocketMixin],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      loadUserAuthorizedData: function () {
        this.initExportRequestsSocket()
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getIE559ReportForPage,
        page: 1,
        onePage: 1,

        // filterIp: '',
        // filterCity: '',
        // filterRegion: '',
        // filterCountry: '',
        filterDate: [],

        filterUserId: '',
        filterUserName: '',

        countFilterParams: 0,
        filterGetParams: {},

      }
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        this.initExportRequestsSocket()
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    methods: {
      checkTabPermission() {

        // if(!this.isAdmin) {
        //   return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        // }

        // this.activeTabByPermissions('navTabs', {
        //   [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
        //   [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        //   [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
        //   [DPD_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        // })
        //
        // this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
        //
        // if(!this._.find(this.navTabs, {active: true}).permissionVisible){
        //   this.setActivePermissionVisibleTab('navTabs')
        //   return
        // }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          ip: this.$route.query.ip,
          // city: this.$route.query.city,
          // region: this.$route.query.region,
          // country: this.$route.query.country,
          date: this.$route.query.date,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setIE559ReportFilter', window.location.search)

        if (next) this.$store.commit('setNextIE559ReportPage', true)
        this.$store.dispatch('fetchIE559Report', url).then(() => {
          this.$store.commit('setNextIE559ReportPage', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'user_id',
              // filterIp: 'ip',
              // filterCity: 'city',
              // filterRegion: 'region',
              // filterCountry: 'country',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'created_at',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      initExportRequestsSocket() {
        if(!this.loadUserRoleAndAuthorizedData) return

        /**
         * Create Socket Channel
         * @type {*|{channel, active, event, user}|{active}}
         */
        let prepareSocket = this.prepareCreatePrivateSocketChannel('GenerateIE559')
        console.log(11111122222);
        console.log(prepareSocket);
        if(prepareSocket.active) {
          this.createIE559ReportSocket(
              prepareSocket,
              (response) => {
                console.log(333333);
                console.log(response.data);
                if(response.data?.user_id && response.data?.status === 'generated') {
                  this.reload()
                }
              })
        }
      },
    },


    beforeDestroy() {
      if (this.$store.getters.getRequestReturnSocket) {
        this.destroySocketChannel('GenerateIE559', 'setIE559ReportSocket')
      }
    },

  }
</script>

<style scoped>

</style>