<template>
    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small"
          >
            {{item.ip}}
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <TableUserColumn
                    v-if="isAdmin"
                    :item="item"
                    :mobile="true"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('connectionsHistory_city.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item.city"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('connectionsHistory_region.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item.region"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('connectionsHistory_country.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item.country"
                />
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('connectionsHistory_timeOfVisit.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
              </div>
            </div>

          </div>

          <div class="table-card__item-btn pl-2">

          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";

  export default {
    name: "ConsolidationUnionTableMobile",

    components: {
      TableUserColumn,
      ValueHelper,
    },

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        hideEditField: false,
      }
    },

    methods: {

    },

  }
</script>

<style scoped>
  .conso-user-select {
    min-width: 150px;
  }

  .table-card .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }
</style>
