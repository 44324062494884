<template>
    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small"
          >
            {{item.id}}
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row"
               v-if="isAdmin">
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <TableUserColumn
                    :item="item"
                    :mobile="true"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('common_name.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item.name"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('common_date.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item.created_at"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('IE559_DateOfReport.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item.report_date"
                />
              </div>
            </div>

          </div>

          <div class="table-card__item-btn pl-2">
            <div class="table-card__item-btn-i" v-if="_.has(item.letter_document_file, 'id')">
              <LinkButton
                  :value="$t('IE559_letterDocument.localization_value.value')"
                  :type="'docs'"
                  @click.native="$emit('getLetterDocument')"
              />
            </div>
            <div class="table-card__item-btn-i" v-if="_.has(item.form_document_file, 'id')">
              <LinkButton
                  :value="$t('IE559_formDocument.localization_value.value')"
                  :type="'docs'"
                  @click.native="$emit('getFormDocument')"
              />
            </div>
            <div class="table-card__item-btn-i" v-if="isAdmin">
              <LinkButton
                  :value="$t('common_delete.localization_value.value')"
                  :type="'docs'"
                  @click.native="$emit('deleteItem')"
              />
            </div>
          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";

  export default {
    name: "IE559ReportTableMobile",

    components: {
      LinkButton,
      TableUserColumn,
      ValueHelper,
    },

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        hideEditField: false,
      }
    },

    methods: {

    },

  }
</script>

<style scoped>
  .conso-user-select {
    min-width: 150px;
  }

  .table-card .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }
</style>
