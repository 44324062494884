<template>
  <div>
    <IE559ReportHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <IE559ReportFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <IE559ReportTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import IE559ReportHead from "./IE559ReportHead/IE559ReportHead";
  import IE559ReportFilter from "./IE559ReportFilter/IE559ReportFilter";
  import IE559ReportTable from "./IE559ReportTable/IE559ReportTable";

  export default {
    name: "IE559ReportTableSection",

    components: {
      IE559ReportHead,
      IE559ReportFilter,
      IE559ReportTable,
    },

    props: {
      typeShops: String,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
