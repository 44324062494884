<template>
  <div class="fragment">
    <template v-if="$store.getters.getConnectionsHistory.length > 0 && !$store.getters.getConnectionsHistoryLoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'connectionsHistory_ip',
          'common_user',
          'connectionsHistory_city',
          'connectionsHistory_region',
          'connectionsHistory_country',
          'connectionsHistory_timeOfVisit',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('connectionsHistory_ip.localization_value.value')}}</th>
            <th>{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('connectionsHistory_city.localization_value.value')}}</th>
            <th>{{$t('connectionsHistory_region.localization_value.value')}}</th>
            <th>{{$t('connectionsHistory_country.localization_value.value')}}</th>
            <th>{{$t('connectionsHistory_timeOfVisit.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getConnectionsHistory"
              :key="index"
          >
            <td>
              {{item.ip}}
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.city"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.region"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item.country"
              />
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getConnectionsHistory"
                 :key="index"
            >
              <ConnectionsHistoryTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getConnectionsHistoryCommonList.next_page_url !== null && $store.getters.getConnectionsHistory.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextConnectionsHistoryPage}"
                :count="$store.getters.getConnectionsHistoryCommonList.total - $store.getters.getConnectionsHistoryForPage * $store.getters.getConnectionsHistoryCommonList.current_page < $store.getters.getConnectionsHistoryForPage ?
                  $store.getters.getConnectionsHistoryCommonList.total - $store.getters.getConnectionsHistoryForPage * $store.getters.getConnectionsHistoryCommonList.current_page:
                  $store.getters.getConnectionsHistoryForPage"
            />
<!--            <ExportBtn-->
<!--                @click.native="$emit('downloadFiles')"-->
<!--                class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getConnectionsHistoryLoading === false && $store.getters.getConnectionsHistory.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import ConnectionsHistoryTableMobile from "./ConnectionsHistoryTableMobile/ConnectionsHistoryTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "NewPaymentsTable",

    components: {
      ValueHelper,
      TableUserColumn,
      NoResult,
      ConnectionsHistoryTableMobile,
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        hideEditField: false,

        show1: false,
        show2: false,

        isModalInfoPopup: false,
        infoPopupItemId: 0,
      }
    },

    methods: {

    }

  }

</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 356px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .table-manage-list {
    width: 240px;


    .manager-btn {
      max-width: 370px;
      width: 100%;
    }
  }


  @media (max-width: 1900px){
    .comment-row{
      width: 250px;
    }
  }

  @media (max-width: 1400px){
    .comment-row{
      width: 200px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .conso-user-select {
    min-width: 150px;
  }

  .site-table .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

</style>
