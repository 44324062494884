<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup shipping-company-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['IE559_ChangeFormData'])"></div>
        {{ $t('IE559_ChangeFormData.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="shipping-company-popup__body">
          <div class="shipping-company-popup__content">
            <div class="custom-row">
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
<!--                <div class="admin-edit" @click="editTranslate(['profile_companyName'])"></div>-->
                <DefaultInput
                    :label="$t('profile_companyName.localization_value.value')"
                    v-bind:class="{'ui-no-valid': UserIE559Data.validation.companyName}"
                    :errorTxt="$t(`${UserIE559Data.validationTranslate.companyName}.localization_value.value`)"
                    :error="UserIE559Data.validation.companyName"
                    v-model="UserIE559Data.data.companyName"
                />
              </div>
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
<!--                <div class="admin-edit" @click="editTranslate(['heartysan_EtsyShops'])"></div>-->
                <DefaultInput
                    :label="$t('common_address.localization_value.value')"
                    v-bind:class="{'ui-no-valid': UserIE559Data.validation.companyAddress}"
                    :errorTxt="$t(`${UserIE559Data.validationTranslate.companyAddress}.localization_value.value`)"
                    :error="UserIE559Data.validation.companyAddress"
                    v-model="UserIE559Data.data.companyAddress"
                />
              </div>
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
<!--                <div class="admin-edit" @click="editTranslate(['heartysan_EtsyShops'])"></div>-->
                <DefaultInput
                    :label="$t('IE559_docNumber.localization_value.value')"
                    v-bind:class="{'ui-no-valid': UserIE559Data.validation.docNumber}"
                    :errorTxt="$t(`${UserIE559Data.validationTranslate.docNumber}.localization_value.value`)"
                    :error="UserIE559Data.validation.docNumber"
                    v-model="UserIE559Data.data.docNumber"
                />
              </div>
              <div class="custom-col"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
<!--                <div class="admin-edit" @click="editTranslate(['heartysan_EtsyShops'])"></div>-->
                <DefaultInput
                    :label="$t('common_vatNumber.localization_value.value')"
                    v-bind:class="{'ui-no-valid': UserIE559Data.validation.vatNumber}"
                    :errorTxt="$t(`${UserIE559Data.validationTranslate.vatNumber}.localization_value.value`)"
                    :error="UserIE559Data.validation.vatNumber"
                    v-model="UserIE559Data.data.vatNumber"
                />
              </div>
            </div>
          </div>

        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['common_save'])"></div>
        <MainButton
            class="custom-popup__btn-i wfc"
            :value="$t('common_save.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import {UserIE559Data} from "@/components/modules/IE559ReportModule/models/UserIE559Data";

export default {
  name: "UserIE599DataPopup",
  components: {
    DefaultInput,
    Modal,
    MainButton,
  },

  props: {
    userId: Number,
  },

  data() {
    return {
      UserIE559Data: new UserIE559Data(),
    }
  },

  mounted() {
    if(this.userId) {
      this.UserIE559Data.data.userId = this.userId
    }

    if(this._.has(this.$store.getters.getIE559ReportCommonList.consolidation_form_user_data, 'company_name')) {
      this.UserIE559Data.setItem(this.$store.getters.getIE559ReportCommonList.consolidation_form_user_data)
    }
  },

  methods: {
    save() {

      if(!this.UserIE559Data.dataValidation()) {
        return
      }

      let data = this.UserIE559Data.prepareData()

      this.$store.dispatch('saveUserIE559Form', data).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            this.openNotify('success', 'common_notificationRecordChanged')
            this.$emit('close')
            this.$emit('reload')

            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })

    },
  }
}
</script>

<style scoped lang="scss">

@import "../../../../../scss/colors";
@import "../../../../../scss/mixins/mixins";

.shipping-company-popup {

  &__body {
    display: flex;

    @include for-680 {
      flex-direction: column;
    }
  }

  &__content {
    width: 100%;

    @include from-680 {
      padding-right: 20px;
    }
  }

  &__file {
    max-width: 105px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include for-680 {
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-bottom: 35px;
    }

    input[type="file"] {
      display: none;
    }
  }

  &__file-label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__file-img {
    display: flex;
    flex-direction: column;

    img {
      width: 105px;
      height: 105px;
      object-fit: contain;
    }
  }

  &__file-text {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $brown;
  }

}

.custom-popup__btn {

}

.custom-popup__btn-i {
  margin-right: 10px;
  min-width: 122px;
  width: fit-content;
  margin-bottom: 10px;

  &.secondary {
    border-width: 2px;
  }
}
</style>
